import React, { FC, useEffect, useState } from 'react';
import { Button, Container, Icon, Link } from '@legalshield/adonis-ux-framework';

import Config from '../../config';
import LinkMembershipModal from '../Payments/Subscriptions/LinkMembershipModal/LinkMembershipModal';
import URLs from '../../utils/url';
import { useLaunchDarkly } from '../../hooks/useLaunchDarkly';
import { useLocation } from 'react-router-dom';

export const LinkToAccess: FC = () => {
  const location = useLocation();

  const [showLinkMembershipModal, setShowLinkMembershipModal] = useState(false);
  const { linkMembershipFlow } = useLaunchDarkly();

  useEffect(() => {
    if (location.pathname === '/link-membership') setShowLinkMembershipModal(true);
  }, [location.pathname]);

  const getAccessUrl = () => {
    if (window.location.href.includes(':3401')) {
      return URLs.getLSAppUrl(Config.currentEnv.apps.access + '.' + Config.currentEnv.domain);
    } else return URLs.getLSAppUrl(Config.currentEnv.apps.access);
  };
  const url = getAccessUrl();

  return (
    <Container flexbox alignItems="center" justifyContent="center" classNames={['mt-5']} background="none">
      <Container flexDirection="row" background="none">
        {linkMembershipFlow ? (
          <Button
            variant="tertiary"
            iconColor="P200"
            iconLeft="alert_information"
            textSize="medium"
            label={string_table.MEMBERSHIPS_ACCESS_LINK}
            onClick={() => {
              setShowLinkMembershipModal(true);
            }}
            classNames={['text-nowrap']}
          />
        ) : (
          <Container flexbox background="light-gray" alignItems="center" justifyContent="center" classNames={['mt-5']}>
            <Link
              classNames={['text-nowrap']}
              icon="alert_information"
              iconPosition="left"
              href={url}
              text={string_table.MEMBERSHIPS_ACCESS_LINK}
              textSize="medium"
              textWeight="semibold"
            />
            <Icon name="arrow_chevron_right" size="small" />
          </Container>
        )}
      </Container>
      {showLinkMembershipModal && (
        <LinkMembershipModal
          onOpenChange={setShowLinkMembershipModal}
          closeButton
          closeFunction={() => setShowLinkMembershipModal(false)}
        />
      )}
    </Container>
  );
};
