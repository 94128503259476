import React, { FC, ReactNode } from 'react';
import { Grid, GridCol, GridRow } from '@legalshield/adonis-ux-framework';
import cn from 'classnames';

import CardButton from './CardButton';
import { NavButton } from '../NavigationBar/NavigationBar';

import './CardGrid.scss';

export const renderGridCardButtons = (cards: NavButton[]) =>
  cards.map((card, index) => (
    <GridCol key={index}>
      <CardButton heading={card.name} iconName={card.iconName} text={card.text} to={card.data} />
    </GridCol>
  ));

interface CardGridProps {
  children?: ReactNode | ReactNode[];
  classNames?: cn.Argument;
}

const CardGrid: FC<CardGridProps> = ({ children, classNames }) => (
  <Grid classNames={cn('basic-grid', classNames)}>
    <GridRow variant="halves" style={{ display: 'flex', justifyContent: 'left', marginTop: '4px' }}>
      {children as React.ReactElement}
    </GridRow>
  </Grid>
);

export default CardGrid;
