import React, { FC } from 'react';
import { Button, Card, GridCol, Heading, Text } from '@legalshield/adonis-ux-framework';

import Config from '../../config';
import URLs from '../../utils/url';
import { Layout } from '../Layout/Layout';

const Resources: FC = () => {
  const crumbs = [
    { label: string_table.OVERVIEW_BREADCRUMB, link: '/overview' },
    { label: string_table.RESOURCES_TITLE, link: '/resources' },
  ];

  return (
    <Layout crumbs={crumbs} title={string_table.RESOURCES_TITLE} subtitle={string_table.RESOURCES_DESCRIPTION}>
      <GridCol classNames={['mb-4']}>
        <Card cardHeight="100%" onClick={() => (window.location.href = URLs.getLSAppUrl(Config.currentEnv.apps.forms))}>
          <Card.Content>
            <img
              src={'https://design.api.' + pplsi.realmDomain + '/assets/logos/legalshield-logo.svg'}
              alt="LegalShield Logo"
            />
            <Heading as="T20" text={string_table.RESOURCES_FORMS_LS} />
            <Text as="p">{string_table.RESOURCES_FORMS_LS_DESCRIPTION}</Text>
            <Card.Actions align={'right'}>
              <Button
                label={string_table.RESOURCES_FORMS_LS}
                onClick={() => (window.location.href = URLs.getLSAppUrl(Config.currentEnv.apps.forms))}
              />
            </Card.Actions>
          </Card.Content>
        </Card>
      </GridCol>
      <GridCol classNames={['mb-4']}>
        <Card cardHeight="100%" onClick={() => window.open('https://legalshield.perkspot.com/login', '_new')}>
          <Card.Content>
            <img
              src={'https://avatars.legalshield.com/accounts/en-us/default/0/36c94a3e-aa67-4178-bf8d-d27f689874de.png'}
              alt="Member Perks Logo"
            />
            <Heading as="T20" text={string_table.RESOURCES_MEMBER_PERKS} />
            <Text as="p">{string_table.RESOURCES_MEMBER_PERKS_DESCRIPTION}</Text>
            <Card.Actions align={'right'}>
              <Button
                label={string_table.RESOURCES_MEMBER_PERKS}
                onClick={() => window.open('https://legalshield.perkspot.com/login', '_new')}
              />
            </Card.Actions>
          </Card.Content>
        </Card>
      </GridCol>
    </Layout>
  );
};

export default Resources;
