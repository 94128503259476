import React, { FC, Fragment, useState, useEffect } from 'react';
import {
  Button,
  Container,
  Form,
  FormField,
  Heading,
  Icon,
  Image,
  Input,
  InputValidationStatus,
  Modal,
  ModalProps,
  Text,
  Tooltip,
} from '@legalshield/adonis-ux-framework';

import './LinkMembershipModal.scss';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import successIcon from '../../../Payments/ConfirmUpgradeModal/successIcon.svg';
import CallForQuestions from '../CallForQuestions/CallForQuestions';
import ActivateService from '../../../../services/activate.service';
import { InvitationPresenter } from '../../../../presenters';
import { IInvitation, InvitationResponseSecurityQuestion } from '../../../../interfaces/invitation.interface';
import { OWNER_CANNOT_ACCEPT_INVITATION_API_ERROR } from '../../../../config';
import { useLaunchDarkly } from '../../../../hooks/useLaunchDarkly';
import { useSearchParams } from 'react-router-dom';

/**
 * TODO: Handle 0 question flow
 */

interface LinkMembershipModalProps extends ModalProps {
  onActivate?: (memberNumber: string, lastName: string) => Promise<void>;
}

export interface IField {
  hint?: string;
  status?: 'valid' | 'invalid' | 'warning';
  value?: string | boolean | number;
  checked?: boolean;
  match?: string;
}

enum WizardSteps {
  ChooseCodeType,
  EnterCode,
  SecurityQuestions,
  Success,
}

export enum CodeType {
  MemberNumber = 'memberNumber',
  AssociateNumber = 'associateNumber',
  AccountCode = 'accountCode',
  Unknown = 'unknown',
}

const LinkMembershipModal: FC<LinkMembershipModalProps> = ({ ...props }) => {
  // Form hooks
  const [searchParams] = useSearchParams();
  const urlParamMemberNumber = searchParams.get('code');
  const urlParamCodeType = searchParams.get('codeType');

  const { chooseCodeType } = useLaunchDarkly();
  const [step, setStep] = useState(chooseCodeType ? WizardSteps.ChooseCodeType : WizardSteps.EnterCode);
  const [memberNumber, setMemberNumber] = useState('');
  const [securityQuestions, setSecurityQuestions] = useState<Array<InvitationResponseSecurityQuestion>>([]);
  const [securityAnswers, setSecurityAnswers] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [validationStatus, setValidationStatus] = useState<InputValidationStatus>(null);
  const [invitationPresenterState, setInvitationPresenterState] = useState<InvitationPresenter | null>(null);
  const [codeType, setCodeType] = useState<CodeType>((urlParamCodeType as CodeType) ?? CodeType.Unknown);

  const invalidCredentials = string_table.INVITATION_INVALID_CREDENTIALS;

  useEffect(() => {
    if (urlParamMemberNumber && !memberNumber) setMemberNumber(urlParamMemberNumber);
    if (location.pathname.includes('/activate')) handleStepChange(WizardSteps.EnterCode);
    if (location.pathname.includes('/access')) handleStepChange(WizardSteps.EnterCode);
    if (location.pathname.includes('/associate')) handleStepChange(WizardSteps.EnterCode);
    if (location.pathname.includes('/link-membership') && urlParamCodeType) {
      handleStepChange(WizardSteps.EnterCode);
    } else if (location.pathname.includes('/link-membership') && urlParamMemberNumber) {
      setCodeType(CodeType.Unknown);
      handleStepChange(WizardSteps.EnterCode);
    }
  }, [location.pathname, urlParamMemberNumber, urlParamCodeType]);

  const resetFlow = () => {
    setMemberNumber('');
    setSecurityQuestions([]);
    setSecurityAnswers({});
    setIsLoading(false);
    setErrorMessage('');
    setValidationStatus(null);
    setStep(chooseCodeType ? WizardSteps.ChooseCodeType : WizardSteps.EnterCode);
  };

  const handleStepChange = (step: WizardSteps) => {
    setIsLoading(true);
    setErrorMessage('');
    setValidationStatus('valid');

    if (step === WizardSteps.EnterCode) {
      setValidationStatus(null);
      setStep(step);
      setIsLoading(false);
    } else {
      setTimeout(() => {
        setValidationStatus(null);
        setStep(step);
        setIsLoading(false);
      }, 1000);
    }
  };

  const handleApiFailure = () => {
    setErrorMessage(string_table.APP_FAILURE);
  };

  const acceptInvitation = async (invitation: IInvitation) => {
    try {
      const acceptResponse = await ActivateService.acceptInvitation({
        code: memberNumber,
        invitationId: invitation.id,
        version: invitation.version,
      });
      if (acceptResponse.statusCode === 200) {
        handleStepChange(WizardSteps.Success);
      } else if (
        acceptResponse.statusCode === 422 &&
        acceptResponse.bodyContent &&
        JSON.parse(acceptResponse.bodyContent)?.message === OWNER_CANNOT_ACCEPT_INVITATION_API_ERROR
      ) {
        resetFlow();
      } else {
        setErrorMessage(invalidCredentials);
      }
    } catch {
      handleApiFailure();
    }
  };

  const handleMemberNumberSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsLoading(true);
    setErrorMessage(null);
    try {
      const submitCodeResponse = await ActivateService.submitCode(memberNumber);

      if (submitCodeResponse.statusCode === 200 && submitCodeResponse.body) {
        const invitation: IInvitation = submitCodeResponse.body;
        const invitationPresenter = new InvitationPresenter(invitation);

        setInvitationPresenterState(invitationPresenter);

        if (invitationPresenter.hasSecurityQuestions()) {
          setSecurityQuestions(invitationPresenter.securityQuestions());
          handleStepChange(WizardSteps.SecurityQuestions);
        } else {
          acceptInvitation(invitation);
        }
      } else {
        setErrorMessage(invalidCredentials);
      }
    } catch (error) {
      handleApiFailure();
    } finally {
      setIsLoading(false);
    }
  };

  const acceptInvitationFailure = () => {
    setErrorMessage(string_table.VERIFY_IDENTITY_FAILURE);
  };

  const handleSecurityQuestionsSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsLoading(true);
    setErrorMessage(null);

    try {
      const invitation = invitationPresenterState?.invitation;
      if (invitation) {
        const transformedSecurityAnswers = Object.keys(securityAnswers).map((key) => ({
          id: key, // Replace `key` with the actual property name for the ID.
          answer: securityAnswers[key], // Replace `securityAnswers[key]` with the actual property name for the answer.
        }));

        const acceptResponse = await ActivateService.acceptInvitation({
          code: invitation.verification?.code || '',
          invitationId: invitation.id,
          securityQuestions: transformedSecurityAnswers,
          version: invitation.version,
        });

        if (acceptResponse.statusCode === 200) {
          setValidationStatus('valid');
          handleStepChange(WizardSteps.Success);
        } else if (
          acceptResponse.statusCode === 422 &&
          acceptResponse.bodyContent &&
          JSON.parse(acceptResponse.bodyContent)?.message === OWNER_CANNOT_ACCEPT_INVITATION_API_ERROR
        ) {
          resetFlow();
        } else {
          setValidationStatus('invalid');
          acceptInvitationFailure();
        }
      } else {
        setValidationStatus('invalid');
        handleApiFailure();
      }
    } catch {
      setValidationStatus('invalid');
      handleApiFailure();
      setIsLoading(false);
    }
  };

  const memberNumberChange = (memberNumber) => {
    setValidationStatus(null);
    setErrorMessage('');
    setMemberNumber(memberNumber);
  };

  const handleAnswerChange = (questionId, value) => {
    setValidationStatus(null);
    setErrorMessage('');
    setSecurityAnswers({ ...securityAnswers, [questionId]: value });
  };

  const continueBtnDisabled = isLoading || !memberNumber;
  const submitBtnDisabled = isLoading || securityQuestions.some((question) => !securityAnswers[question.id]);

  const continueBtnText = isLoading ? 'Loading...' : 'Continue';
  const submitBtnText = isLoading ? 'Loading...' : 'Link membership';

  const subtitleCopy: Record<CodeType, string> = {
    [CodeType.MemberNumber]: string_table.LINK_MEMBERSHIP_MEMBER_NUMBER_SUBTITLE,
    [CodeType.AssociateNumber]: string_table.LINK_MEMBERSHIP_ASSOCIATE_NUMBER_SUBTITLE,
    [CodeType.AccountCode]: string_table.LINK_MEMBERSHIP_ACCOUNT_CODE_SUBTITLE,
    [CodeType.Unknown]: string_table.LINK_MEMBERSHIP_UNKNOWN_SUBTITLE,
  };

  const inputLabelCopy: Record<CodeType, string> = {
    [CodeType.MemberNumber]: string_table.LINK_MEMBERSHIP_MEMBER_NUMBER_LABEL,
    [CodeType.AssociateNumber]: string_table.LINK_MEMBERSHIP_ASSOCIATE_NUMBER_LABEL,
    [CodeType.AccountCode]: string_table.LINK_MEMBERSHIP_ACCOUNT_CODE_LABEL,
    [CodeType.Unknown]: string_table.LINK_MEMBERSHIP_UNKNOWN_LABEL,
  };

  const accountCodeClicked = (codeType) => () => {
    setCodeType(codeType);
    handleStepChange(WizardSteps.EnterCode);
  };

  return (
    <Modal
      closeButton={!isLoading}
      closeFunction={() => !isLoading && props.closeFunction?.()}
      position="center"
      contentProps={{
        onEscapeKeyDown: (e) => e.preventDefault(),
        onInteractOutside: (e) => e.preventDefault(),
      }}
      {...props}
    >
      {step === WizardSteps.ChooseCodeType && chooseCodeType && (
        <Container>
          <Modal.Title>{string_table.LINK_MEMBERSHIP_ACCOUNT_CODE_TYPE_TITLE}</Modal.Title>
          <Text textSize="large" text={string_table.LINK_MEMBERSHIP_ACCOUNT_CODE_TYPE_SUBTITLE} classNames={['mb-2']} />
          <FormField id="choose-code-type" validationHint={errorMessage} status={validationStatus}>
            <Container flexbox flexDirection="column" justifyContent="center">
              <Button
                variant="primary"
                label="Member Number"
                onClick={accountCodeClicked(CodeType.MemberNumber)}
                classNames={['mt-4']}
              />
              <Button
                variant="primary"
                label="Account Code"
                onClick={accountCodeClicked(CodeType.AccountCode)}
                classNames={['mt-4']}
              />
              <Button
                variant="primary"
                label="Associate Number"
                onClick={accountCodeClicked(CodeType.AssociateNumber)}
                classNames={['mt-4']}
              />
              <Button
                variant="tertiary"
                label="I don’t know"
                onClick={accountCodeClicked(CodeType.Unknown)}
                classNames={['mt-4']}
              />
            </Container>
          </FormField>
        </Container>
      )}
      {step === WizardSteps.EnterCode && (
        <Container>
          <Form
            id="link-membership__enter-code"
            onSubmit={(e) => {
              e.preventDefault();
              handleMemberNumberSubmit(e);
            }}
          >
            <Modal.Title>Link your membership</Modal.Title>
            <Text
              textSize="large"
              text={subtitleCopy[codeType] ?? string_table.LINK_MEMBERSHIP_UNKNOWN_SUBTITLE}
              classNames={['mb-2']}
            />
            <FormField
              id="membership-number"
              label={inputLabelCopy[codeType]}
              validationHint={errorMessage}
              status={validationStatus}
            >
              <Input
                autoFocus
                placeholder={inputLabelCopy[codeType]}
                value={memberNumber}
                onChange={(e) => memberNumberChange(e.currentTarget.value)}
                required
                classNames={['mb-2']}
              />
            </FormField>
            {codeType === CodeType.MemberNumber && (
              <Tooltip
                placement="top"
                arrow
                text=" "
                theme="material"
                tooltipHTML={
                  <>
                    <p style={{ fontWeight: 'normal' }}>{string_table.TOOLTIP_P_1}</p>
                    <p style={{ fontWeight: 'normal' }}>{string_table.TOOLTIP_P_2}</p>
                    <p style={{ fontWeight: 'normal' }}>
                      {string_table.TOOLTIP_P_3_A}
                      <strong>{string_table.TOOLTIP_P_3_B}</strong>
                      {string_table.TOOLTIP_P_3_C}
                    </p>
                  </>
                }
              >
                <Container
                  background="none"
                  flexbox
                  alignContent="center"
                  justifyContent="center"
                  width={'100%'}
                  classNames={'pt-5'}
                >
                  <Icon name="warning_circle_warning" size="small" classNames={['mr-2']} />
                  <Text textSize="small" text="What is a member number?" />
                </Container>
              </Tooltip>
            )}
            <Modal.Actions>
              <Button
                variant="primary"
                label={continueBtnText}
                onClick={handleMemberNumberSubmit}
                disabled={continueBtnDisabled}
              />
            </Modal.Actions>
            <CallForQuestions phoneNumber="8447148494" />
          </Form>
        </Container>
      )}
      {step === WizardSteps.SecurityQuestions && (
        <Container>
          <Form
            id="link-membership__security-questions"
            onSubmit={(e) => {
              e.preventDefault();
              handleSecurityQuestionsSubmit(e);
            }}
          >
            <Modal.Title>Link your membership</Modal.Title>
            <Text
              textSize="large"
              text="Please answer the following security questions to link your membership."
              classNames={['mb-2']}
            />
            <Fragment>
              {securityQuestions.map((question) => (
                <FormField
                  key={question.id}
                  id={`security-question-${question.id}`}
                  label={question.question}
                  required
                  validationHint={errorMessage}
                  status={validationStatus}
                >
                  <Input
                    autoFocus={question.id === securityQuestions[0].id}
                    classNames={['mb-2']}
                    onChange={(e) => handleAnswerChange(question.id, e.currentTarget.value)}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter' && question.id === securityQuestions[securityQuestions.length - 1].id) {
                        handleSecurityQuestionsSubmit(e);
                      }
                    }}
                    value={securityAnswers[question.id] || ''}
                  />
                </FormField>
              ))}
            </Fragment>
            <Modal.Actions>
              <Button
                variant="primary"
                label={submitBtnText}
                onClick={handleSecurityQuestionsSubmit}
                disabled={submitBtnDisabled}
              />
            </Modal.Actions>
            <CallForQuestions phoneNumber="8447148494" />
          </Form>
        </Container>
      )}
      {step === WizardSteps.Success && (
        <>
          <Image src={successIcon} alt="successIcon" height={32} width={32} />
          <Container background="none" flexbox flexDirection={'column'} alignItems={'center'}>
            <Heading
              as="T20"
              text="Your membership has been linked" // TODO: Add translations
              classNames={['confirm-upgrade-modal__title', 'mt-4']}
            />
            <Text
              text="Start using your benefits today!" // TODO: Add translations
              textSize="large"
              classNames={['confirm-upgrade-modal__text', 'mb-4', 'mt-4']}
            />
          </Container>
          <Modal.Actions>
            <Button variant="primary" label="Link another membership" onClick={() => resetFlow()} />
            <Button variant="secondary" label={string_table.DONE} onClick={props.closeFunction} />
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
};

export default LinkMembershipModal;
